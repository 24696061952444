<template>
  <div class="page about">
    <!-- About the Project -->
    <div class="about-dear-climate">
      <div class="mini-subheader">About</div>
      <h1 class="rot-2">Dear Climate</h1>
      <div class="tagline">
        You're changing fast.<br />
        We want to change with you.
      </div>
      <div class="about-text">
        <p>
          DEAR CLIMATE has many incarnations: posters, guided meditations,
          installations, workshops. Founded in 2012 by Una Chaudhuri, Fritz
          Ertl, Oliver Kellhammer, and Marina Zurkow, DEAR CLIMATE is an ongoing
          creative-research project that hacks the aesthetics of instructional
          signage and the techniques of meditation to lead viewers and listeners
          towards a better informed, more realistic, and more affectionate
          relationship to the more-than-human world, including geo-physical
          forces, and others species.
        </p>
        <p>Currently active members are Una Chaudhuri and Marina Zurkow.</p>
      </div>
    </div>
    <!-- For Inquiries -->
    <InfoCallout :title="infoCallout.title" :text="infoCallout.text" />
    <!-- <div class="inquiries-info">
      <div class="content">
        <h2>Inquiries &amp; Collaborations</h2>
        <p>
          If you would like to find out more, or discuss future installations
          and interventions, please contact us at
          <a href="mailto:dearclimate@gmail.com">dearclimate@gmail.com</a>
        </p>
      </div>
    </div> -->
    <!-- Members Bios -->
    <div class="about-members">
      <h2>People</h2>
      <div class="bio-item">
        <div class="bio-photo">
          <img src="../assets/profile-imgs/Marina-Zurkow-Profile-Pic.png" />
        </div>
        <div class="bio-text">
          <h3>Marina Zurkow</h3>
          <p>
            Media and participatory practice artist
            <strong>Marina Zurkow</strong> connects people to nature-culture
            tensions and environmental messes, offering humor and new ways of
            knowing, connecting, and feeling. She works as a founding member of
            several collaborative initiatives, including
            <em>Making the Best of It: Nimble Foods for Climate Change</em>,
            <em>Dear Climate</em>, <em>Climoji</em>, and
            <em>Investing in Futures</em>. Zurkow's recent solo exhibitions
            include ICA San Diego, New York City’s MTA Arts &amp; Design,
            bitforms gallery, New York and Borusan Contemporary, Istanbul. She
            is a 2011 Guggenheim Fellow and received grants from NYFA, NYSCA,
            the Rockefeller Foundation, and Creative Capital. She is represented
            by bitforms gallery in New York.
          </p>
        </div>
      </div>
      <div class="bio-item">
        <div class="bio-photo">
          <img src="../assets/profile-imgs/Una-Chaudhuri-Profile-Pic.png" />
        </div>
        <div class="bio-text">
          <h3>Una Chaudhuri</h3>
          <p>
            <strong>Una Chaudhuri</strong> teaches at New York University, where
            she is also the Dean for Humanites and the Vice-Dean for
            Interdisciplinary Initiatives. Her recent books include The Stage
            Lives of Animals: Zooësis and Performance, Animal Acts: Performing
            Species Today (co-edited with Holly Hughes) and Ecocide: Research
            Theatre and Climate Change (co-authored with Shonni Enelow). She is
            a founding member of the CLIMATE LENS theater collective.
          </p>
        </div>
      </div>
    </div>
    <!-- Additional Thanks -->
    <div class="additional-credits">
      <h2>Additional Credits</h2>
      <p>
        <strong
          ><a
            href="https://patshiu.com/"
            target="_blank"
            style="border-bottom: 0"
            >Pat Shiu</a
          ></strong
        >
        designed and developed this website.
      </p>
      <p>
        <strong>Pejk Malinovski</strong> produced and designed the audio
        meditations.
      </p>
    </div>
  </div>
</template>

<script>
// import LinkPrompter from "@/components/LinkPrompter.vue";
import InfoCallout from "@/components/InfoCallout.vue";

import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: `DEAR CLIMATE: About`,
      description: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
        },
        {
          property: `og:site_name`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `http://www.dearclimate.net`,
        },
        {
          property: `og:image`,
          content: `http://www.dearclimate.net/dear-climate-og-img.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: `http://www.dearclimate.net/about`,
        },
        {
          name: `twitter:title`,
          content: computed(() => siteData.title),
        },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        {
          name: `twitter:image`,
          content: `http://www.dearclimate.net/dear-climate-og-twitter-img.jpg`,
        },
      ],
    });
  },
  name: "About",
  components: {
    InfoCallout,
  },
  data: function () {
    return {
      infoCallout: {
        title: "Inquiries & Collaborations",
        text: "If you would like to find out more, or discuss future installations and interventions, please contact us at [dearclimate@gmail.com](mailto:dearclimate@gmail.com)",
      },
    };
  },
};
</script>
<style scoped lang="scss">
@import "../assets/style-vars.scss";
.about-dear-climate {
  padding: 8rem 4rem 2rem 4rem;
  .mini-subheader {
    text-transform: uppercase;
  }
  h1 {
    margin-top: 1.4rem;
    margin-bottom: 1.2rem;
    font-size: 5.4rem;
    line-height: 1;
  }
  .tagline {
    font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: 600;
    font-size: 2rem;
    color: black;
    margin-bottom: 4rem;
    line-height: 1.2;
  }
  .about-text {
    p {
      color: black;
      text-align: left;
      font-size: $p-large;
      font-weight: 400;
    }
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .about-dear-climate {
    h1 {
      font-size: 6.4rem;
    }
    .tagline {
      font-size: 2.8rem;
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

.inquiries-info {
  border-width: 1px 0;
  border-style: solid;
  border-color: $light-gray;
  padding: 6rem 2rem;
  margin: 6rem 2rem;
  .content {
    max-width: 400px;
    margin: 0 auto;
    h2 {
      font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
      font-weight: 500;
      font-size: 2.2rem;
    }
    p {
      font-size: 1.8rem;
    }
  }
}
.about-members {
  .bio-item {
    display: flex;
    flex-direction: column;
    max-width: 760px;
    margin: 0 auto 4rem auto;
    .bio-photo {
      padding: 4rem;
      img {
        max-width: 300px;
      }
    }
    .bio-text {
      padding: 2rem;
      text-align: left;
      h3 {
        font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica,
          Arial, sans-serif;
        font-weight: 500;
        font-size: 2.8rem;
      }
      p {
        font-size: 1.8rem;
        font-weight: 400;
      }
    }
  }
}
.additional-credits {
  margin: 2rem 2rem 8rem 2rem;
  padding: 8rem 2rem 2rem 2rem;
  border-top: 1px solid black;
  h2 {
    font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: 500;
    font-size: 2.2rem;
  }
  p {
    margin: 0;
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
  .about-members {
    .bio-item {
      flex-direction: row;
    }
  }
}
/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}
</style>
